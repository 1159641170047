.App {
  background-color: #25292D;
  color: #f2f2f2;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: scroll;
}

a {
  color: white;
}

.Error {
  color: red;
}

.Success {
  color: green;
}

.SubmitButton {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
  height: 40px;
  color: black;
  background-color: #f8a145;
}

.SubmitButton:hover {
  color: black;
  background-color: #f07900;
}