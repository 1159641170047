.NavBar {
    overflow: hidden;
    width: 100%;
    height: 72px;
    background-color: #16181C;
    margin: 0 auto;
    padding: 10px;
}

.NavBar .NavLogo {
    position: fixed;
    top: 0;
    left: 0;
}

.NavBar .NavLogo img {
    height: 72px;
}

.NavBar .NavLinks {
    float: right;
}

.NavBar .NavLinks button {
    float: left;
    color: #f2f2f2;
    background-color: #25292D;
    text-align: center;
    padding: 14px 16px;
    margin-left: 5px;
    text-decoration: none;
    font-size: 17px;
}

.NavBar .NavLinks button:hover {
    background-color: #ddd;
    color: black;
}
