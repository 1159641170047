table.rotation {
    margin: auto;
    width: 800px;
    border-collapse: collapse;
    border: 1px solid #000000;
    color: #000000;
    margin-bottom: 20px;
    font-family: Roboto,sans-serif;
    font-weight: lighter;
}

table.rotation th.title {
    background-color: #f07900;
    font-size: large;
    font-weight: bold;
}

table.rotation th {
    padding: 3px;
    background-color: #f8a145;
}

table.rotation th, table.rotation td {
    padding: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: #000000;
    text-align: center;
}

table.rotation td {
    color: white;
    background-color: #4f565e;
}

table.rotation td.volunteer_slot {
    background-color: #808080;
}

table.rotation td.volunteer_slot .inactive {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}

table.rotation tr.vacant_range td {
    background-color: #a9a9a9;
}

table.rotation th.timeslot {
    width: 100px;
}
